/* Copyright 2024 "Holloway" Chew, Kean Ho <kean.ho.chew@zoralab.com>
 * Copyright 2024 ZORALab Enterprise <tech@zoralab.com>
 *
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not
 * use this file except in compliance with the License. You may obtain a copy of
 * the License at:
 *                http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations under
 * the License.
 */
window.addEventListener('load', function() {
	function handleViewingElements(entries, observer) {
		entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add('viewing');
		} else {
			entry.target.classList.remove('viewing');
		}
		});
	}

	let options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.0
	};
	let observer = new IntersectionObserver(handleViewingElements, options);
	let elements = document.getElementsByTagName("*");
	for (let i = 0; i < elements.length; i++) {
		observer.observe(elements[i]);
	}
});

/* Copyright (C) 2024 ZORALab Enterprise <tech@zoralab.com>
 *
 *
 * ZORALab Enterprise Proprietary License 1.0
 *
 * Licensed under ZORALab Enterprise Proprietary License (the “License”);
 * you may ONLY use this file except in compliance with the License.
 *
 * All information contained herein is, and remains the property of
 * ZORALab Enterprise and its suppliers, if any. The intellectual and
 * technical concepts contained herein are proprietary to ZORALab Enterprise
 * and its suppliers and may be covered by Malaysia Patent Law, patents in
 * process, and are protected by trade secret or copyright law. Dissemination of
 * this information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from ZORALab Enterprise.
 */
